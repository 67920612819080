body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#headertitle {
  color: #ff3334;
  display: inline;
  font-weight: bold;
  font-size: 34px;
  line-height: 34px;
  font-family: "Antic Didone", serif;
  position: relative;
  left: 0.55em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ff3334;
  font-family: "Quicksand", sans-serif;
}

ul {
  list-style: none;
  padding: 0;
  font-size: 18px;
  margin: 0 0.5em;
}

li {
  padding: 0.5em 0 0 0;
}

p {
  font-family: "Quicksand", sans-serif;
  margin: 0.5em;
}

a {
  text-decoration: none;
}

img.responsive {
  object-fit: cover;
  height: 150px;
  min-width: 100%;
  max-width: 500px;
}

.top {
  display: inline-block;
  margin: 0.5em 0.5em 0 0.5em;
}

.top img {
  position: absolute;
  z-index: 1;
}

nav ul {
  border-bottom: #ff3334 solid 2px;
  padding: 0em 0 0.3em 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

nav li {
  display: block;
  padding: 0;
}

nav a {
  color: rgb(70, 70, 70);
  font-size: 28px;
  font-family: "AmaticSC", "Arial Narrow", Arial, sans-serif;
  text-decoration: none;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
}

nav a.active {
  color: black;
  cursor: pointer;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.top a.active img {
  filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.5));
}

.top #settingicon {
  right: 0.5em;
}

.top #headericon {
  left: 0.4em;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 0.5em;
  padding: 0.5em;
}

.search-form input {
  font-size: 20px;
  position: static;
  width: calc(100% - 1em);
  margin: 0.5em 0 0.5em 0.5em;
  padding: 0.4em 0;
  text-indent: 1.5em;
}

#unauthenticated {
  text-align: center;
  color: #9f6000;
  background: #feefb3;
  padding: 0.25em 0;
}

.categories ul {
  margin: 0;
}

.categories .listitem {
  margin: 0.25em 0.5em;
}

.dish .title,
.category .title {
  font-family: "Quicksand", sans-serif;
  padding: 0.5em 0.5em;
  font-size: 20px;
}

.dish .title,
.category .title,
.search-form,
.header {
  position: relative;
  background-color: #f1e6de;
}

.search-form input,
.dish input,
.dish textarea,
.dish select,
.category input,
.settings input,
.settings select,
#color-btn {
  border: black solid 1px;
  box-shadow: 0px 1px 8px 0px #777;
  background-color: white;
  text-align: left;
}

.search-form .clear-icon,
.search-form .add-icon {
  margin-top: 0.8em;
  padding: 0.7em 0.5em;
  cursor: pointer;
  position: absolute;
}

.one-user,
.two-users {
  transition: all 300ms;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
}

.one-user.active,
.two-users.active {
  filter: invert(50%) sepia(82%) saturate(428%) hue-rotate(172deg)
    brightness(90%) contrast(92%) drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
  transform: scale(2);
  z-index: 1;
}

.suggestions {
  padding: 0.5em;
  font-family: "Quicksand", sans-serif;
}

.suggestions span {
  font-size: 18px;
}

.delete-icon {
  float: right;
}

.container {
  padding: 0.5em;
  margin: auto;
  max-width: 1024px;
}

.flex {
  display: flex;
  flex: 1 1;
}

.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}

.dish .wrapper,
.category .wrapper {
  padding-top: 1em;
  clear: both;
  overflow: auto;
  background-color: white;
}

.markdown-preview.markdown-preview {
  display: none;
}

@media screen and (min-width: 768px) {
  .markdown-preview.markdown-preview {
    display: flex;
    padding-left: 2em;
    padding-bottom: 4em;
  }
}

.dish label span,
.category label span,
.settings label span {
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  width: 120px;
  clear: both;
  float: left;
  text-align: right;
  padding: 0.6em 0.5em;
}

.dish input,
.dish textarea,
.dish select,
.category input,
.settings input,
.settings select,
#color-btn {
  font-size: 16px;
  padding: 0.5em 0.3em;
  margin: 0.5em 0;
  border-radius: 4px;
  float: left;
}

.dish input,
.dish textarea,
.category input,
.settings input,
#color-btn {
  width: calc(100% - 120px - 2em - 0.6em);
}

.dish select,
.settings select,
#color-btn {
  width: calc(100% - 120px - 1.89em);
  cursor: pointer;
}

#color-btn img {
  float: right;
  margin: 0.2em 0.5em 0 0;
}

.dish textarea {
  resize: vertical;
  height: auto;
}

.doneBtn,
.cancelBtn,
.deleteBtn,
.saveBtn,
.loginBtn,
.logoutBtn {
  cursor: pointer;
  padding: 0.2em 0;
  font-family: "Quicksand", sans-serif;
  font-size: 22px;
  color: black;
  border-radius: 3px;
  border: black solid 1px;
  box-shadow: 3px 3px 5px #888;
  position: fixed;
  margin: 0.5em 0;
  bottom: 0;
}

.doneBtn,
.cancelBtn,
.deleteBtn {
  width: calc((100% / 3) - 0.75em);
}

.saveBtn,
.loginBtn,
.logoutBtn {
  width: calc(50% - 0.75em);
}

.deleteBtn {
  background-color: #fb9191;
  left: 0.5em;
}

.cancelBtn {
  background-color: #eeeba0;
  left: calc((100% / 3) + (0.75em / 2));
}

.doneBtn {
  background-color: #c8eea0;
  right: 0.5em;
}

.saveBtn {
  background-color: #f1e6de;
  right: 0.5em;
}

.loginBtn {
  background-color: #f1e6de;
  color: inherit;
  text-decoration: none;
  left: 0.5em;
  width: calc(100% - 2 * 0.5em);
}

.logoutBtn {
  background-color: #eeeeee;
  left: 0.5em;
}

.add-icon {
  left: 1em;
}

.clear-icon {
  right: 1em;
}

.listitem {
  border-bottom: grey solid 0.5px;
  padding: 0.5em 0;
  position: relative;
}

/* Customize the label (the listitem label) */
.listitem label {
  font-family: "Quicksand", sans-serif;
  width: calc(100% - 2em);
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

/* Hide the browser's default checkbox */
.listitem label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.dishItem {
  position: relative;
}

.dishImg {
  cursor: pointer;
  object-fit: cover;
  vertical-align: bottom;
  min-width: 100%;
  max-width: 100%;
  height: 100px;
}

.dishText {
  font-family: "Quicksand", sans-serif;
  padding-left: 35px;
  font-size: 22px;
}

.dishImgText {
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dishEdit {
  cursor: pointer;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}

/* When the checkbox is checked, add a blue background */
.dishes input:checked ~ .checkmark {
  background-color: #ff3334;
}

/* Show the checkmark when checked */
.dishes input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.dishes .checkmark:after {
  left: 6px;
  top: -2px;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}

/* Create a custom checkbox */
.dishes .checkmark {
  background-color: #eee;
}

/* Create the checkmark/indicator (hidden when not checked) */
.dishes .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.section {
  font-family: "Quicksand", sans-serif;
  padding-left: 0.5em;
  margin-top: 0.3em;
  font-size: 20px;
  font-weight: 500;
}

.dishes .done {
  background-color: rgb(80, 80, 80);
  border-radius: 5px 5px 0 0;
  margin: 0.5em 0 0 0;
  padding: 0 0 0.5em 0em;
  color: white;
}

.dishes .done h2 {
  color: white;
  font-family: "Quicksand", sans-serif;
  font-weight: normal;
  margin-left: 0.3em;
  display: inline;
}

.dishes .done li {
  text-decoration: line-through;
}

.dishes .done button {
  position: absolute;
  right: 0;
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  border-radius: 5px;
  border: outset 2px white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  width: calc(100% / 3);
  margin: 0.5em;
  cursor: pointer;
  background-color: #767676;
  color: white;
}

.setting-wrapper {
  background-color: #fff;
  overflow: auto;
  position: absolute;
  width: 100%;
}

.hidden {
  display: none;
}

.categories .checkmark,
.dishes .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 27px;
  width: 27px;
}

.categories .checkmark {
  background-image: url("../assets/icons/reorder.svg");
  background-size: contain;
}

.color-list {
  background-color: white;
  border: black solid 1px;
  clear: both;
  float: right;
  margin: -0.48em 0.6em 0 0;
  border-radius: 4px;
  box-shadow: 0px 1px 8px 0px #777;
  width: calc(100% - 120px - 1.85em);
  text-align: center;
}

.color-list li {
  cursor: pointer;
  width: 2em;
  height: 2em;
  display: inline-block;
  box-shadow: 1px 1px 5px #888888;
  border-radius: 35%;
  margin: 0.25em;
  padding: 0;
}

.settings {
  text-align: center;
}

.not-found {
  text-align: center;
}

.not-found :nth-child(odd) {
  transform: rotate(-5deg);
}

.not-found :nth-child(even) {
  transform: rotate(5deg);
}

.userform img {
  border-radius: 50%;
  margin: 0.5em;
  width: 50px;
}

:focus {
  outline: none;
}

/* FONT: Antic Didone */
@font-face {
  font-family: "Antic Didone";
  src: url("../assets/fonts/AnticDidone-Regular.ttf");
}

/* FONT: AmaticSC */
@font-face {
  font-family: "AmaticSC";
  src: url("../assets/fonts/AmaticSC-Regular.ttf");
}

/* FONT: PatrickHandSC */
@font-face {
  font-family: "PatrickHandSC";
  src: url("../assets/fonts/PatrickHandSC-Regular.ttf");
}

/* FONT: Quicksand */
@font-face {
  font-family: "Quicksand";
  src: url("../assets/fonts/Quicksand-VariableFont_wght.ttf");
}

/* FONT: Comfortaa */
@font-face {
  font-family: "Comfortaa";
  src: url("../assets/fonts/Comfortaa-VariableFont_wght.ttf");
}
